import React, { useEffect, useState } from "react";
import { Sidenav, Nav } from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import "rsuite/Sidenav/styles/index.css";
import "rsuite/Nav/styles/index.css";
import { allbanner } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);
  const [role, setRole] = useState<string | null>(null);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const getMe = async () => {
    try {
      const response = await allbanner(ApiEndPoints("getSubadminme"));
      setUserData(response.subadmin);
    } catch (error) {
      console.error("Error fetching subadmin data:", error);
    }
  };

  useEffect(() =>{
    setRole(localStorage.getItem("role"));
    getMe();
  }, []);

  const handleNav = (eventKey: any) => {
    switch (eventKey) {
      case "1":
        navigate("/");
        break;
      case "2":
        navigate("/custom-orders");
        break;
      case "3":
        navigate("/products");
        break;
      case "4":
        navigate("/new-product");
        break;
      case "5":
        navigate("/banners");
        break;
      case "6":
        navigate("/custom-banner");
        break;
        case "7":
        navigate("/coupon");
        break;
      case "8":
        navigate("/freebies");
        break;
      case "9":
        navigate("/transaction");
        break;
      case "10":
        navigate("/customers");
        break;
      case "11":
        navigate("/sub-admin");
        break;
      default:
        break;
    }
  };

  // Define the navigation items
  const navItems = [
    { eventKey: "1", path: "/", label: "Home", icon: "fi fi-sr-house-chimney" },
    { eventKey: "2", path: "/custom-orders", label: "Custom Order", icon: "fi fi-sr-order-history" },
    { eventKey: "3", path: "/products", label: "Product", icon: "fi fi-sr-crown" },
    { eventKey: "4", path: "/new-product", label: "New Product", icon: "fi fi-sr-box-open-full" },
    { eventKey: "5", path: "/banners", label: "Banners", icon: "fi fi-sr-ad" },
    { eventKey: "6", path: "/custom-banner", label: "Custom Banner", icon: "fi fi-sr-banner-5" },
    { eventKey: "7", path: "/coupon", label: "Coupons", icon: "fi fi-sr-ticket" },
    { eventKey: "8", path: "/freebies", label: "Freebies", icon: "fi fi-sr-feedback-alt" },
    { eventKey: "9", path: "/transaction", label: "Transaction", icon: "fi fi-sr-sack-dollar" },
    { eventKey: "10", path: "/customers", label: "Customer", icon: "fi fi-sr-hr-group" },
    { eventKey: "11", path: "/sub-admin", label: "Admin Access", icon: "fi fi-sr-user-gear" },
  ];

  return (
    <div className="sidebar">
      <div style={{ width: 240 }}>
        <Sidenav defaultOpenKeys={["3", "4"]}>
          <Sidenav.Body>
            <div className="d-flex justify-content-center align-items-center py-2">
              <img className="img-fluid logo-img" src="/assets/rg-logo.png" alt="Logo" />
            </div>
            <Nav>
              {/* If role is admin, show all navigation items */}
              {role === "admin" && navItems.map((navItem) => (
                <Nav.Item
                  key={navItem.eventKey}
                  eventKey={navItem.eventKey}
                  onClick={() => handleNav(navItem.eventKey)}
                  id={location.pathname === navItem.path ? "active" : ""}
                  icon={<i className={`${navItem.icon} nav-Icons`}></i>}
                >
                  <a className="sm-hide">{navItem.label}</a>
                </Nav.Item>
              ))}

              {/* If role is sub-admin, show only allowed tabs */}
              {role !== "admin" && userData?.isActive && userData?.accessTabs?.length > 0 &&
                userData.accessTabs.map((tab: string) => {
                  const navItem = navItems.find(item => item.label.toLowerCase() === tab.toLowerCase());
                  return navItem ? (
                    <Nav.Item
                      key={navItem.eventKey}
                      eventKey={navItem.eventKey}
                      onClick={() => handleNav(navItem.eventKey)}
                      id={location.pathname === navItem.path ? "active" : ""}
                      icon={<i className={`${navItem.icon} nav-Icons`}></i>}
                    >
                      <a className="sm-hide">{navItem.label}</a>
                    </Nav.Item>
                  ) : null;
                })
              }

              <Nav.Item
                onClick={handleLogout}
                icon={<i className="fi fi-bs-sign-out-alt nav-Icons"></i>}
              >
                <a className="sm-hide">Logout</a>
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    </div>
  );
};

export default NavBar;
