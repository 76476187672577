import axios from "axios";
import { API_URL } from "./Config";
import { Navigate } from "react-router-dom";

axios.defaults.withCredentials = true;

const role = localStorage.getItem("role");

const getAuthToken = () => {
  if (role === "admin") {
    return localStorage.getItem("dashtoken");
  } else {
    return localStorage.getItem("subadmintoken");
  }
};

const getHeaders = () => {
  const token = getAuthToken();
  // console.log(token,"token");
  return {
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
};

// Login
export const loginUser = async (
  endpoint: string | URL,
  data: { email: string; password: string }
) => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        withCredentials: true,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Post product
export const postProducts = async (endpoint: string | URL, data: any) => {
  const token = getAuthToken();
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//All Products

export const allProducts = async (endpoint: string) => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
};

//All customer

export async function allCustomer(endpoint: string) {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
}

//All single customer
export const getCustomers = async (endpoint: string, productId: any) => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// product id
export const fetchProductIds = async (
  endpoint: string,
  productId: any,
  data: any
) => {
  const token = getAuthToken();
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Get single product
export const getProduct = async (endpoint: string, productId: any) => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// Delete Product
export const deleteProduct = async (endpoint: string, productId: any) => {
  try {
    const response = await axios.delete(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Update product
export const updateProduct = async (
  endpoint: string,
  productId: any,
  data: any
) => {
  const token = getAuthToken();

  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type":"multipart/form-data"

        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//add to freebies
export const addToFreebies = async (
  endpoint: string,
  productId: any,
  data: any
) => {
  const token = getAuthToken();

  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    alert("error");
    throw error;
  }
};

//All Transaction

export async function allTransaction(endpoint: string, p0?: { email: any }) {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
}

//All single customer transactions
export const getTransaction = async (endpoint: string, productId: any) => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//All Transaction

export async function allCustomorder(endpoint: string) {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
}

//All single customer transactions
export async function getCustomorder(endpoint: string, productId: any) {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    alert("success");
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
}

//All coupons

export async function allCoupon(endpoint: string) {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
}

//All single customer transactions
export async function getCoupon(endpoint: string, productId: any) {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    alert("success");
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
}

//All Banners

export const allbanner = async (endpoint: string) => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
};

//All banner
export async function getallbanner(endpoint: string, productId: any) {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    alert("success");
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
}

//Get single banner
export const getbanner = async (endpoint: string, productId: any) => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Post product
export const addBanner = async (endpoint: string | URL, data: any) => {
  const token = getAuthToken();
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// Delete Product
export const deleteBanner = async (endpoint: string, productId: any) => {
  try {
    const response = await axios.delete(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Update product
export const updateBanner = async (
  endpoint: string,
  productId: any,
  data: any
) => {
  const token = getAuthToken();

  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Post product
export const postBanner = async (endpoint: string | URL, data: any) => {
  const token = getAuthToken();
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

export const updateCoupon = async (
  endpoint: string,
  productId: any,
  data: any
) => {
  const token = getAuthToken();

  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

export const forgetPass = async (endpoint: string,data:any) => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Post custom banner
export const customBanner = async (endpoint: string,data:any) => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};



