import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ApiEndPoints from "../Api/Apiendpoints";
import { allProducts, deleteProduct, updateProduct } from "../Api/Apicalls";
import { API_URL } from "../Api/Config";
import { message } from "antd";

interface Product {
  _id: string;
  image: string;
  name: string;
}

const Freebies = () => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  // Fetch all products
  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getproduct"));
      let filterFreebies = firstResponse.products.filter(
        (item: any) => item.isFreebie === true
      );
      setFilteredProducts(filterFreebies);
    } catch (error) {
      console.error(error);
    }
  };

  const handlepatchProduct = async (id: any) => {
    try {
      const response = await updateProduct(ApiEndPoints("updateProduct"), id, {
        isFreebie: false,
      });
      successmessage("Updated successfully")
      getAllProduct();
    } catch (err) {
      console.log("Error updating product:", err);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <section className="py-4">
      {contextHolder}
      <Container>
        <h2 className="title pb-3">Freebees</h2>
        <h4 style={{ fontSize: "14px", fontWeight: 700 }}>Freebies List</h4>
        <div className="row">
          {filteredProducts.map((product) => (
            <div key={product._id} className="col-lg-2 col-md-2 col-sm-6 col-6">
              <div className="p-2">
                <img
                  className="img-fluid"
                  src={`${API_URL}/public/images/${product.image}`}
                  alt={product.name}
                />
                <Button
                  variant="contained"
                  className="remove-btn"
                  onClick={() => handlepatchProduct(product._id)}
                >
                  Remove
                </Button>
              </div>
            </div>
          ))}
          {filteredProducts.length===0&&<h3 className="text-center pt-3">No freebies found!</h3>}
        </div>
      </Container>
    </section>
  );
};

export default Freebies;
