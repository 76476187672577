import React, { useState, useEffect } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Input, Select, message, TableColumnsType, UploadProps } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import GradeIcon from "@mui/icons-material/Grade";
import {
  addToFreebies,
  allProducts,
  deleteProduct,
  updateProduct,
} from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { API_URL } from "../Api/Config";
import { MdClear } from "react-icons/md";
import { Box } from "@mui/material";
import style from "antd/es/affix/style";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { RcFile } from "antd/es/upload";
import { Table } from "antd";

const Products = () => {
  // State for modal visibility and selected product
  const [opens, setOpens] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [file, setFile] = useState<RcFile | null>(null);

  // State for product data
  const [productDatas, setProductDatas] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  // Fetch all products
  useEffect(() => {
    getAllProduct();
  }, []);

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getproduct"));
      setProductDatas(firstResponse.products);
      console.log(setProductDatas);

      setTotalPages(Math.ceil(firstResponse.products.length / 10)); // Adjust pagination
    } catch (error) {
      console.error(error);
    }
  };

  // Handle opening modal and setting selected product
  const handleOpens = (product: any) => {
    setSelectedProduct(product);
    setOpens(true);
  };

  // Handle closing modal
  const handleCloses = () => {
    setOpens(false);
    setSelectedProduct(null); // Reset selected product
  };

  // Handle updating the product
  const handleUpdateProduct = async () => {
    if (!selectedProduct) return;

    try {
      const formData = new FormData();
      Object.keys(selectedProduct).forEach((key) => {
        if (
          selectedProduct[key] !== undefined &&
          selectedProduct[key] !== null
        ) {
          formData.append(key, selectedProduct[key]);
        }
      });

      if (file) {
        formData.append("image", file);
      }
      const response = await updateProduct(
        ApiEndPoints("updateProduct"),
        selectedProduct._id,
        formData
      );
      setOpens(false);
      getAllProduct();
    } catch (err) {
      console.error("Error updating product:", err);
      alert("Error updating product");
    }
  };

  // Delete Product

  const handleDeleteProduct = async (id: any) => {
    try {
      const response = await deleteProduct(ApiEndPoints("deleteProduct"), id);
      console.log("Product deleted:", response);
      getAllProduct();
    } catch (err) {
      console.error(err);
    }
  };

  // Handle form input changes
  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setSelectedProduct({ ...selectedProduct, [name]: value });
  };

  // Table columns
  const columns: TableColumnsType = [
    {
      title: "Product Id",
      dataIndex: "_id",
      render: (text: string) => <>{text.slice(-5)}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Buying's",
      dataIndex: "buyersCount",
    },
    {
      title: "Ratings",
      dataIndex: "ratingsAverage",
      render: (text: string) => (
        <>
          <GradeIcon className="rating" />
          {text}
        </>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text: string) => (
        <img
          src={`${API_URL}/public/images/${text}`}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "isDeleted",
      render: (text: string, record: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <EditOutlined onClick={() => handleOpens(record)} />
          <DeleteOutlined onClick={() => handleDeleteProduct(record._id)} />
        </div>
      ),
    },
  ];

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedValues, setSelectedValues] = useState<string | null>(null);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


  const beforeUpload = (file: RcFile) => {
    // Store the selected file in the state
    setFile(file);
    setImageUrl(URL.createObjectURL(file));
    return false;
  };
  const props: UploadProps = {
    name: "file",
    multiple: true,
    beforeUpload,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSelectChange = (value: string, fieldName: string) => {
    setSelectedProduct((prevProduct: any) => ({
      ...prevProduct,
      [fieldName]: value, // Update the specific field with the selected value
    }));
  };

  const handleSelectChangeM = (value: string[], fieldName: string) => {
    setSelectedProduct((prevProduct: any) => ({
      ...prevProduct,
      [fieldName]: value, // Update the selected tags
    }));
  };

  interface productData {
    [x: string]: any;
    category: string;
    name: string;
    price: string | number;
    description: string;
    sizes: string;
    stitchCount: string | number;
    colors: string | number;
    formats: string;
    quantity: string;
    slug: string;
    tags: string;
    metaContent: string;
    metaHeading: string;
  }
  const [productData, setProductData] = useState<productData>({
    category: "",
    name: "",
    price: "",
    description: "",
    sizes: "",
    stitchCount: "",
    colors: "",
    formats: "",
    quantity: "",
    slug: "",
    tags: "",
    metaContent: "",
    metaHeading: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  // Freebies

  // State for modal visibility and selected product
  const [openk, setOpenk] = useState(false);

  const handleRowSelectionChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys);

    // Log each selected key from selectedRowKeys using for...in
    for (const index in selectedKeys) {
      const key = selectedKeys[index];
      const indexid = selectedRowKeys.indexOf(key);
      console.log(selectedRowKeys.indexOf(key));
      if (indexid !== -1) {
        console.log(`Key ${key} is at index ${indexid} in selectedRowKeys.`);
      } else {
        console.log(`Key ${key} not found in selectedRowKeys.`);
      }
    }
  };

  const handleButtonClick = async (
    _handleRowSelectionChange: (selectedKeys: React.Key[]) => void
  ) => {
    if (selectedRowKeys.length === 0) {
      alert("No product selected!");
      return;
    }

    // Get the first ID from the selectedRowKeys array
    const idToShow = selectedRowKeys[0];

    try {
      const updatedProducts = [];

      for (const idToShow of selectedRowKeys) {
        // Update the selectedProduct to have a price of 0 and mark as a freebie
        const updatedProduct = { ...selectedProduct, isFreebie: true };

        const response = await addToFreebies(
          ApiEndPoints("addToFreebies"),
          idToShow,
          updatedProduct
        );

        updatedProducts.push(updatedProduct);

      }

      navigate("/freebies", { state: { products: updatedProducts } });

      setOpenk(false);
      getAllProduct();
    } catch (err) {
      console.error("Error updating products in freebies:", err);
    }
  };

  // Filter Product
  interface filterData {
    byProductName: string;
    byProductId: string | null; 
    byProductCategory: string;
  }

  const [filterData, setfilterData] = useState<filterData>({
    byProductName: "",
    byProductId: null,
    byProductCategory: "",
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfilterData({
      ...filterData,
      byProductId: e.target.value || null, // Convert empty strings to null
    });
  };

  const handleFilter = async () => {
    try {
      // Fetch all products
      const firstResponse = await allProducts(ApiEndPoints("getproduct"));
      const products = firstResponse.products;
      const { byProductId, byProductName, byProductCategory } = filterData;

      const filteredProducts = products.filter(
        (product: { _id: string; name: string; category: string }) =>
          (!byProductId || product._id.slice(-5) === byProductId) &&
          (!byProductName ||
            product.name.toLowerCase().includes(byProductName.toLowerCase())) &&
          (!byProductCategory || product.category === byProductCategory)
      );

      // Set filtered products to state
      setProductDatas(filteredProducts);
    } catch (error) {
      console.error("Error fetching or filtering products:", error);
    }
  };

  return (
    <>
      {contextHolder}
      <section className="py-4">
        <Container>
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="title">Products</h2>
            </div>
            <div className="me-2">
              <button
                className="apply-btn"
                onClick={() => handleButtonClick(handleRowSelectionChange)}
              >
                Add to Freebies
              </button>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-lg-2 col-md-2 col-sm-4 col-12">
              <div>
                <label className="label">Name</label>
                <Input
                  className="input"
                  value={filterData.byProductName}
                  onChange={(e) =>
                    setfilterData({
                      ...filterData,
                      byProductName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4 col-12">
              <div>
                <label className="label">Product Id</label>
                <Input
                  className="input"
                  value={filterData.byProductId || ""}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4 col-12">
              <div>
                <label className="label">Categories</label>
                <br />
                <Select
                  className="input"
                  style={{ width: 120 }}
                  value={filterData.byProductCategory}
                  onChange={(value) =>
                    setfilterData({ ...filterData, byProductCategory: value })
                  }
                  options={[
                    {
                      value: "print design",
                      label: "Print design",
                    },
                    {
                      value: "embroidery design",
                      label: "Embroidery design",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2  col-sm-4 col-4">
              <div>
                <label style={{ visibility: "hidden" }}>""</label>
                <br />
                <Button
                  variant="contained"
                  className="btn-apply"
                  onClick={handleFilter}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table
              rowKey="_id"
              rowSelection={{
                selectedRowKeys,
                onChange: handleRowSelectionChange,
              }}
              columns={columns}
              dataSource={productDatas}
            />

            {/* Modal for editing product */}
            <Modal
              show={opens}
              onHide={handleCloses}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Modal.Header className="d-flex justify-content-between">
                <Modal.Title>Edit Product</Modal.Title>
                <MdClear onClick={handleCloses} style={{ cursor: "pointer" }} />
              </Modal.Header>
              <Modal.Body>
                {selectedProduct && (
                  <Box sx={{ ...style }}>
                    <div className="row">
                      <div
                        className="col-lg-6 col-md-6 col-sm-12"
                        style={{ width: "100%" }}
                      >
                        <div>
                          <label className="label py-2">Product Name</label>
                          <Input
                            value={selectedProduct.name}
                            placeholder=""
                            name="name"
                            onChange={handleInputChange}
                          />
                          <label className="label py-2">Upload image</label>
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ color: "#FF518A" }} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                          {imageUrl === "" ? (
                            <img
                              src={`${API_URL}/public/images/${selectedProduct.image}`}
                              style={{ width: "20%" }}
                            />
                          ) : (
                            <img src={imageUrl} style={{ width: "20%" }} />
                          )}
                          <br />
                          <label className="label py-2">Price</label>
                          <Input
                            name="price"
                            placeholder=""
                            value={selectedProduct.price}
                            onChange={handleInputChange}
                          />
                          <label className="label py-2">Category</label>
                          {/* <Select
                            // name="category"
                            value={selectedProduct.category}
                            style={{ width: "100%" }}
                            className="rg-input"
                            onChange={(value) =>
                              handleSelectChange(value, "category")
                            }
                            options={[
                              { value: "print design", label: "Print design" },
                              {
                                value: "embroidery design",
                                label: "Embroidery design",
                              },
                            ]}
                          /> */}
                          <select
                            name="category"
                            id="category"
                            value={selectedProduct?.category || ""} // Set the selected value from the state
                            onChange={(e) =>
                              handleSelectChange(e.target.value, "category")
                            } // Get the selected value on change
                            style={{ width: "100%" }}
                          >
                            <option value="print design">Print design</option>
                            <option value="embroidery design">
                              Embroidery design
                            </option>
                          </select>

                          {(selectedProduct?.category === "embroidery design" ||
                            selectedValue === "embroidery design") && (
                            <div>
                              <div className="input-container">
                                <label className="input-label">
                                  Sizes(Inches)
                                </label>
                                &nbsp;&nbsp;
                                <Input
                                  placeholder="Eg 7 * 7"
                                  className="rg-input"
                                  name="sizes"
                                  value={selectedProduct.sizes}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="input-container">
                                <label className="input-label">
                                  Stitch Count
                                </label>
                                &nbsp;&nbsp;
                                <Input
                                  placeholder="Eg 38982"
                                  className="rg-input"
                                  name="stitchCount"
                                  value={selectedProduct.stitchCount}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="input-container">
                                <label className="input-label">Colors</label>
                                &nbsp;&nbsp;
                                <Input
                                  placeholder="Eg 14"
                                  className="rg-input"
                                  name="colors"
                                  value={selectedProduct.colors}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="input-container">
                                <label className="input-label">Formats</label>
                                &nbsp;&nbsp;
                                <Input
                                  placeholder="Eg EXP,HUS,PES,DST,SEW,JEF,PCS,VP3,XXX,PDF"
                                  className="rg-input"
                                  value={selectedProduct.formats}
                                  name="formats"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="input-container">
                                <label className="input-label">Quantity</label>
                                &nbsp;&nbsp;
                                <Input
                                  placeholder="Eg 1 Size"
                                  name="quantity"
                                  className="rg-input"
                                  value={selectedProduct.quantity}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          )}
                          <label className="label py-2">Tags</label>

                          <select
                            value={selectedProduct?.tags || []} 
                            onChange={(e) => {
                              const selectedOptions = Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              );
                              handleSelectChangeM(selectedOptions, "tags");
                            }}
                            style={{ width: "100%" }}
                            className="rg-input"
                          >
                            <option value="adventure">Adventure</option>
                            <option value="animals">Animals</option>
                            <option value="awareness">Awareness</option>
                            <option value="back-to-school">
                              Back To School
                            </option>
                            <option value="beauty">Beauty</option>
                            <option value="beauty-fashion">
                              Beauty Fashion
                            </option>
                            <option value="birds">Birds</option>
                            <option value="birthday">Birthday</option>
                            <option value="camping">Camping</option>
                            <option value="celebration">Celebration</option>
                            <option value="Christmas">Christmas</option>
                            <option value="craft-sewing">Craft Sewing</option>
                            <option value="design-bundles">
                              Design Bundles
                            </option>
                            <option value="dogs-pets">Dogs Pets</option>
                            <option value="easter">Easter</option>
                            <option value="fairy-tails">Fairy Tails</option>
                            <option value="fantasy">Fantasy</option>
                            <option value="farming">Farming</option>
                            <option value="fashion">Fashion</option>
                            <option value="festivals">Festivals</option>
                            <option value="feathers">Feathers</option>
                            <option value="fishing">Fishing</option>
                            <option value="flowers">Flowers</option>
                            <option value="food">Food</option>
                            <option value="farmes">Farmes</option>
                            <option value="friends-family">
                              Friends Family
                            </option>
                            <option value="halloween">Halloween</option>
                            <option value="holidays">Holidays</option>
                            <option value="insects">Insects</option>
                            <option value="july4th">July 4th</option>
                            <option value="key-fob">Key Fob</option>
                            <option value="line-art">Line Art</option>
                            <option value="monogram">Monogram</option>
                            <option value="nature">Nature</option>
                            <option value="ocean">Ocean</option>
                            <option value="ornament">Ornament</option>
                            <option value="outdoor">Outdoor</option>
                            <option value="people">People</option>
                            <option value="plants">Plants</option>
                            <option value="quotes">Quotes</option>
                            <option value="religious">Religious</option>
                            <option value="remembrance">Remembrance</option>
                            <option value="saying">Saying</option>
                            <option value="season-designs">
                              Season Designs
                            </option>
                            <option value="sketch-pattern">
                              Sketch Pattern
                            </option>
                            <option value="sports">Sports</option>
                            <option value="sports-outdoor">
                              Sports Outdoor
                            </option>
                            <option value="thanksgiving">Thanksgiving</option>
                            <option value="transport">Transport</option>
                            <option value="valentines-day">
                              Valentines Day
                            </option>
                          </select>

                          {/* <label className="label py-2">Meta heading</label>
                          <Input
                            name="metaHeading"
                            value={selectedProduct.metaHeading}
                            placeholder=""
                            onChange={handleInputChange}
                          /> */}
                          {/* <label className="label py-2">Meta Content</label>
                          <TextArea
                            rows={4}
                            placeholder=""
                            value={selectedProduct.metaContent}
                            name="metaContent"
                            onChange={handleInputChange}
                          /> */}
                          <label className="label py-2">URL format:</label>
                          <Input
                            name="slug"
                            placeholder=""
                            value={selectedProduct.slug}
                            onChange={handleInputChange}
                          />

                          <label className="label py-2">
                            Product Description
                          </label>
                          <TextArea
                            rows={4}
                            value={selectedProduct.description}
                            placeholder=""
                            name="description"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="text-center py-3">
                        <Button
                          variant="contained"
                          className="submit-btn btn apply-btn"
                          onClick={handleUpdateProduct}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </Box>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Products;
