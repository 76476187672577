import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, message, Input } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import CloseIcon from "@mui/icons-material/Close";
import TextArea from "antd/es/input/TextArea";
import ApiEndPoints from "../Api/Apiendpoints";
import {
  allbanner,
  deleteBanner,
  postBanner,
  updateCoupon,
} from "../Api/Apicalls";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { API_URL } from "../Api/Config";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const { Dragger } = Upload;

const Banners = () => {
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [heading, setHeading] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBannerId, setCurrentBannerId] = useState<string | null>(null);

  useEffect(() => {
    getAllBanners();
  }, []);

  const getAllBanners = async () => {
    try {
      const response = await allbanner(ApiEndPoints("getbanner"));
      setBanners(response.heroPages);
    } catch (error) {
      message.error("Error fetching banners");
      console.error("Error fetching banners:", error);
    }
  };

  const beforeUpload = (file: RcFile) => {
    setFileList([file]);
    const previewUrl = URL.createObjectURL(file);
    setPreview(previewUrl);
    return false;
  };

  const handleSubmitBanner = async () => {
    if (!heading || !content || fileList.length === 0) {
      message.error("Please fill all fields and upload an image.");
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("heading", heading);
      formData.append("content", content);
      if (fileList[0].originFileObj) {
        formData.append("image", fileList[0].originFileObj as RcFile);
      }

      if (isEditing && currentBannerId) {
        // PATCH request for updating
        await updateCoupon(
          ApiEndPoints("postBanner"),
          currentBannerId,
          formData
        );
        message.success("Banner updated successfully!");
      } else {
        // POST request for adding
        await postBanner(ApiEndPoints("postBanner"), formData);
        message.success("Banner added successfully!");
      }

      getAllBanners();
      resetForm();
    } catch (error) {
      message.error("An error occurred while submitting the banner.");
      console.error("Error submitting banner:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setHeading("");
    setContent("");
    setFileList([]);
    setPreview(undefined);
    setOpen(false);
    setIsEditing(false);
    setCurrentBannerId(null);
  };

  const handleOpen = () => {
    resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (banner: any) => {
    setCurrentBannerId(banner._id);
    setHeading(banner.heading);
    setContent(banner.content);
    setPreview(`${API_URL}/public/images/${banner.image}`);
    setIsEditing(true);
    setOpen(true);
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleDelete = async (id: any) => {
    try {
      await deleteBanner(ApiEndPoints("allbanner"), id);
      await getAllBanners();
      message.success("Banner deleted successfully!");
    } catch (error) {
      message.error("Failed to delete banner!");
    }
  };

  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <Container>
        <div className="text-end pb-3">
          <Button
            variant="contained"
            className="bg-gradient border-0 rounded-pill py-2 px-4 mt-3 add-banner"
            onClick={handleOpen}
          >
            Add Banner
          </Button>
        </div>

        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>Image</TableCell>
                  <TableCell sx={{ width: "20%" }}>Heading</TableCell>
                  <TableCell sx={{ width: "40%" }}>Content</TableCell>
                  <TableCell sx={{ width: "10%" }}>Edit</TableCell>
                  <TableCell sx={{ width: "10%" }}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banners.map((row: any, i: any) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ width: "20%" }}>
                      <img
                        src={`${API_URL}/public/images/${row.image}`}
                        className="img-fluid"
                        style={{ width: "30%" }}
                        alt="Banner"
                      />
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>{row.heading}</TableCell>
                    <TableCell sx={{ width: "40%" }}>
                      {truncateText(row.content, 100)}
                    </TableCell>
                    <TableCell sx={{ width: "10%" }}>
                      <FaEdit
                        style={{
                          color: "green",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEdit(row)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "10%" }}>
                      <MdDeleteForever
                        style={{
                          color: "red",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDelete(row._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>

      <Modal open={open}>
        <Box sx={{ ...style, overflowY: "auto", maxHeight: "90vh" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {isEditing ? (
                <h2 style={{ fontSize: "22px" }}>Edit Banner</h2>
              ) : (
                <h2 style={{ fontSize: "22px" }}>Add Banner</h2>
              )}
            </div>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <label className="py-2" style={{ fontWeight: 600 }}>
            Heading
          </label>
          <Input
            placeholder="Enter Heading"
            className=""
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
          <label className="py-2" style={{ fontWeight: 600 }}>
            Content
          </label>
          <TextArea
            placeholder="Enter Content"
            className=""
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={4}
          />
          <label className="py-2" style={{ fontWeight: 600 }}>
            Image
          </label>
          <Dragger
            beforeUpload={beforeUpload}
            fileList={fileList}
            onChange={handleChange}
            listType="picture-card"
          >
            {fileList.length === 0 && uploadButton}
          </Dragger>

          {/* Image Preview */}
          {preview && (
            <div className="mt-3">
              <img
                src={preview}
                alt="Preview"
                style={{ width: "40%", objectFit: "contain" }}
              />
            </div>
          )}

          <Button
            variant="contained"
            className="bg-gradient border-0 rounded-pill py-2 px-4 mt-3 add-banner "
            onClick={handleSubmitBanner}
            disabled={loading}
          >
            {loading
              ? isEditing
                ? "Updating..."
                : "Adding..."
              : isEditing
              ? "Update"
              : "Add"}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Banners;
