import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Input, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { message, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Button } from "@mui/material";
import { postProducts } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { useNavigate } from "react-router-dom";
import { blob } from "stream/consumers";
import { RcFile, UploadChangeParam } from "antd/lib/upload";

const { Option } = Select;

const { Dragger } = Upload;

function NewProduct() {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedValues, setSelectedValues] = useState<string | null>(null);
  const navigate = useNavigate();
  const [file, setFile] = useState<RcFile | null>(null);
  const [zip, setZip] = useState<RcFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [zipName, setZipName] = useState<string | null>(null);

  interface productData {
    category: string;
    name: string;
    price: string | number;
    description: string;
    sizes: string;
    stitchCount: string | number;
    colors: string | number;
    formats: string;
    quantity: string;
    slug: string;
    tags: string;
    metaContent: string;
    metaHeading: string;
  }
  const [productData, setProductData] = useState<productData>({
    category: "",
    name: "",
    price: "",
    description: "",
    sizes: "",
    stitchCount: "",
    colors: "",
    formats: "",
    quantity: "",
    slug: "",
    tags: "",
    metaContent: "",
    metaHeading: "",
  });

  // Validate image files
  const beforeUploadImage = (file: RcFile) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Only image files are allowed!");
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.onload = () => setImageUrl(reader.result as string);
    reader.readAsDataURL(file);
    setFile(file);
    return false;
  };

  // Validate zip files
  const beforeUploadZip = (file: RcFile) => {
    const isZip =
      file.type === "application/zip" ||
      file.type === "application/x-zip-compressed";
    if (!isZip) {
      message.error("Only ZIP files are allowed!");
      return Upload.LIST_IGNORE;
    }
    setZipName(file.name);
    setZip(file);
    return false;
  };

  const uploadPropsImage: UploadProps = {
    name: "file",
    beforeUpload: beforeUploadImage,
  };

  const uploadPropsZip: UploadProps = {
    name: "file",
    beforeUpload: beforeUploadZip,
  };

  const handleSelectChange = (value: any, name: any) => {
    setProductData({ ...productData, [name]: value });
    setSelectedValue(value);
  };

  const handleSelectChangeM = (value: any, name: any, tags: any) => {
    setProductData({ ...productData, [name]: value });
    setSelectedValues(value);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  // Handle form changes
  function handleInputChange(e: { target: { name: any; value: any } }) {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  }

  // Handle product posting
  const handlePostProduct = async () => {
    if (selectedValue === "embroidery design") {
      // List of required fields
      const requiredFields = [
        { value: selectedValue || "", name: "category" },
        { value: productData.name, name: "name" },
        { value: productData.price, name: "price" },
        { value: productData.description, name: "description" },
        { value: productData.sizes, name: "sizes" },
        { value: productData.stitchCount, name: "stitchCount" },
        { value: productData.colors, name: "colors" },
        { value: productData.formats, name: "formats" },
        { value: productData.quantity, name: "quantity" },
        { value: productData.slug, name: "slug" },
        { value: productData.tags, name: "tags" },
        // { value: productData.metaHeading, name: "metaHeading" },
        // { value: productData.metaContent, name: "metaContent" },
        { value: file, name: "File" },
        { value: zip, name: "Zip" },
      ];
      // Check for missing fields
      const missingFields = requiredFields.filter(
        (field) =>
          !field.value ||
          (typeof field.value === "string" && field.value.trim() === "")
      );

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields
          .map((field) => field.name)
          .join(", ");
        message.error(
          `Please fill out the following fields: ${missingFieldNames}`
        );
        return;
      }
    }
    // print design
    else {
      // List of required fields
      const requiredFields = [
        { value: selectedValue || "", name: "category" },
        { value: productData.name, name: "name" },
        { value: productData.description, name: "description" },
        { value: productData.slug, name: "slug" },
        { value: productData.tags, name: "tags" },
        // { value: productData.metaHeading, name: "metaHeading" },
        // { value: productData.metaContent, name: "metaContent" },
        { value: file, name: "File" },
        { value: zip, name: "Zip" },
      ];
      // Check for missing fields
      const missingFields = requiredFields.filter(
        (field) =>
          !field.value ||
          (typeof field.value === "string" && field.value.trim() === "")
      );

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields
          .map((field) => field.name)
          .join(", ");
        message.error(
          `Please fill out the following fields: ${missingFieldNames}`
        );
        return;
      }
    }

    try {
      // Create a new FormData object
      const formData = new FormData();
      // Append form fields to FormData
      formData.append("category", productData.category);
      formData.append("name", productData.name);
      formData.append("description", productData.description);
      formData.append("sizes", productData.sizes);
      formData.append("stitchCount", productData.stitchCount.toString());
      formData.append("price", productData.price.toString());
      formData.append("quantity", productData.quantity);
      formData.append("colors", productData.colors.toString());
      formData.append("formats", productData.formats);
      formData.append("slug", productData.slug);
      formData.append("tags", productData.tags);
      formData.append("metaHeading", "demo");
      formData.append("MetaContent", "demo");
      if (file) {
        formData.append("image", file);
      } else {
        console.error("No file selected");
      }
      if (zip) {
        formData.append("zip", zip);
      } else {
        console.error("No file selected");
      }

      // Make the API request
      const result = await postProducts(ApiEndPoints("postProduct"), formData);

      successmessage("Product added successfully!");
      setProductData({
        category: "",
        name: "",
        price: "",
        description: "",
        sizes: "",
        stitchCount: "",
        colors: "",
        formats: "",
        quantity: "",
        metaHeading: "",
        metaContent: "",
        slug: "",
        tags: "",
      });

      setFile(null);
      setZipName(null);
      navigate("/products");
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else if (error.message) {
        errormessage(error.message);
      } else {
        errormessage("Something went wrong");
      }
    }
  };

  return (
    <>
      {contextHolder}
      <section className="py-4">
        <Container>
          <div>
            <h2 className="title">New Products</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 product-upload">
              <div>
                <label className="label py-2">
                  Product Name <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  placeholder=""
                  name="name"
                  onChange={handleInputChange}
                />
                <label className="label py-2">
                  Upload image <span style={{ color: "red" }}>*</span>
                </label>
                <Dragger {...uploadPropsImage}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: "#FF518A" }} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag image file to this area to upload
                  </p>
                </Dragger>
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Selected"
                    style={{ width: "40%", marginTop: "10px" }}
                  />
                )}
                <br />

                <label className="label py-2">
                  Upload zip File <span style={{ color: "red" }}>*</span>
                </label>

                <Dragger {...uploadPropsZip}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: "#FF518A" }} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag ZIP file to this area to upload
                  </p>
                </Dragger>
                {zipName && <p>Selected ZIP file: {zipName}</p>}

                <label className="label py-2">
                  Price <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  name="price"
                  placeholder=""
                  onChange={handleInputChange}
                />
                <label className="label py-2">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  // name="category"
                  value={selectedValue}
                  style={{ width: "100%" }}
                  className="rg-input"
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={[
                    { value: "print design", label: "Print design" },
                    { value: "embroidery design", label: "Embroidery design" },
                  ]}
                />

                {selectedValue === "embroidery design" && (
                  <div>
                    <div className="input-container">
                      <label className="input-label">
                        Sizes(Inches) <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 7 * 7"
                        className="rg-input"
                        name="sizes"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">
                        Stitch Count <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 38982"
                        className="rg-input"
                        name="stitchCount"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">
                        Colors <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 14"
                        className="rg-input"
                        name="colors"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">
                        Formats <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg EXP,HUS,PES,DST,SEW,JEF,PCS,VP3,XXX,PDF"
                        className="rg-input"
                        name="formats"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">
                        Quantity <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 1 Size"
                        name="quantity"
                        className="rg-input"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}

                <label className="label py-2">
                  Tags <span style={{ color: "red" }}>*</span>
                </label>

                <Select
                  // value={}
                  onChange={(value) =>
                    handleSelectChangeM(value, "tags", "tags")
                  }
                  style={{ width: "100%" }}
                  className="rg-input"
                  options={[
                    { value: "adventure", label: "Adventure" },
                    { value: "animals", label: "Animals" },
                    { value: "awareness", label: "Awareness" },
                    { value: "back-to-school", label: "Back To School" },
                    { value: "beauty", label: "Beauty" },
                    { value: "beauty-fashion", label: "Beauty Fashion" },
                    { value: "birds", label: "Birds" },
                    { value: "birthday", label: "Birthday" },
                    { value: "camping", label: "Camping" },
                    { value: "celebration", label: "Celebration" },
                    { value: "Christmas", label: "Christmas" },
                    { value: "craft-sewing", label: "Craft Sewing" },
                    { value: "design-bundles", label: "Design Bundles" },
                    { value: "dogs-pets", label: "Dogs Pets" },
                    { value: "easter", label: "Easter" },
                    { value: "fairy-tails", label: "Fairy Tails" },
                    { value: "fantasy", label: "Fantasy" },
                    { value: "farming", label: "Farming" },
                    { value: "fashion", label: "Fashion" },
                    { value: "festivals", label: "Festivals" },
                    { value: "feathers", label: "Feathers" },
                    { value: "fishing", label: "Fishing" },
                    { value: "flowers", label: "Flowers" },
                    { value: "food", label: "Food" },
                    { value: "farmes", label: "Farmes" },
                    { value: "friends-family", label: "Friends Family" },
                    { value: "halloween", label: "Halloween" },
                    { value: "holidays", label: "Holidays" },
                    { value: "insects", label: "Insects" },
                    { value: "july4th", label: "July4th" },
                    { value: "key-fob", label: "Key Fob" },
                    { value: "line-art", label: "Line Art" },
                    { value: "monogram", label: "Monogram" },
                    { value: "nature", label: "Nature" },
                    { value: "ocean", label: "Ocean" },
                    { value: "ornament", label: "Ornament" },
                    { value: "outdoor", label: "Outdoor" },
                    { value: "people", label: "People" },
                    { value: "plants", label: "Plants" },
                    { value: "quotes", label: "Quotes" },
                    { value: "religious", label: "Religious" },
                    { value: "remembrance", label: "Remembrance" },
                    { value: "saying", label: "Saying" },
                    { value: "season-designs", label: "Season Designs" },
                    { value: "sketch-pattern", label: "Sketch Pattern" },
                    { value: "sports", label: "Sports" },
                    { value: "sports-outdoor", label: "Sports Outdoor" },
                    { value: "thanksgiving", label: "Thanksgiving" },
                    { value: "transport", label: "Transport" },
                    { value: "valentines-day", label: "Valentines Day" },
                  ]}
                />

                {/* <label className="label py-2">Meta heading  <span style={{color:"red"}}>*</span></label>
                <Input
                  name="metaHeading"
                  placeholder=""
                  onChange={handleInputChange}
                />
                <label className="label py-2">Meta Content  <span style={{color:"red"}}>*</span></label>
                <TextArea
                  rows={4}
                  placeholder=""
                  name="metaContent"
                  onChange={handleInputChange}
                /> */}
                <label className="label py-2">
                  URL format:("EX:buy-relatedword-print-design"){" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  name="slug"
                  placeholder=""
                  onChange={handleInputChange}
                />

                <label className="label py-2">
                  Product Description <span style={{ color: "red" }}>*</span>
                </label>
                <TextArea
                  rows={4}
                  placeholder=""
                  name="description"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="text-center py-3">
              <Button
                variant="contained"
                className="submit-btn"
                onClick={handlePostProduct}
              >
                Submit
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default NewProduct;
