import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Table, Input, DatePicker, DatePickerProps, Space } from "antd";
import { allCustomer, allProducts, allTransaction } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { MdClear } from "react-icons/md";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { Button } from "@mui/material";
import { log } from "console";

const Transcation = () => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [emailMap, setEmailMap] = useState<Record<string, string>>({});
  const [productDatas, setProductDatas] = useState<any[]>([]);
  const [ogDatas, setOgDatas] = useState<any[]>([]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [productMap, setProductMap] = useState<Record<string, string>>({});
  const [productData, setProductData] = useState<any[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);

  const fetchTransaction = async () => {
    try {
      const firstresponse = await allTransaction(ApiEndPoints("getTransaction"));
      const response = await allCustomer(ApiEndPoints("getCustomers"));
      const data = response.users;

      // Create a mapping of userId to email
      const emailMapping = data.reduce(
        (acc: Record<string, string>, user: any) => {
          acc[user._id] = user.email;
          return acc;
        },
        {}
      );

      // Fetch products and set state
      await getAllProduct();

      // Enrich transactions with product names
      const enrichedTransactions = firstresponse.transactions.map((transaction: any) => {
        const lineItemsWithProductNames = transaction.lineItems.map((item: any) => ({
          ...item,
          productName: productMap[item.product] || 'Unknown Product',
        }));
        return {
          ...transaction,
          lineItems: lineItemsWithProductNames,
        };
      });
      // Save the email mapping and enriched transactions to state
      setEmailMap(emailMapping);
      setProductDatas(enrichedTransactions.reverse());
      setOgDatas(enrichedTransactions);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getproduct"));
      setProductData(firstResponse.products); 

      // Create a mapping from product ID to product name
      const productMapping = firstResponse.products.reduce(
        (acc: Record<string, string>, product: any) => {
          acc[product._id] = product.name; 
          return acc;
        },
        {}
      );

      // Update state with the new product map
      setProductMap(productMapping);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchTransaction();
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (date: string) => <span>{moment(date).format("YYYY-MM-DD")}</span>,
    },
    {
      title: "Order Id",
      dataIndex: "_id",
      render: (_id: string) => <span>{_id.slice(-5)}</span>,
    },
    {
      title: "Mail Id",
      dataIndex: "user",
      render: (userId: string) => <span>{emailMap[userId]}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount: any) => <span>$ {amount.toFixed(2)}</span>,
    },
    {
      title: "Quantity",
      dataIndex: "lineItems",
      render: (lineItems: any[]) => <span>{lineItems.length}</span>,
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (_id: any) => (
        <div
          onClick={() => handleOpen(_id)}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          View
        </div>
      ),
    },
  ];

  // Calculate total earnings and total orders
  const totalEarnings = productDatas.reduce(
    (total: number, transaction: any) => total + (transaction.amount || 0),
    0
  );

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    const dateValue = Array.isArray(dateString) ? dateString[0] : dateString;
    setSelectedDate(dateValue || null);
    applyFilters(dateValue || null, searchId);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchId(e.target.value);
    applyFilters(selectedDate, e.target.value);
  };

  const applyFilters = (date: string | null, searchId: string) => {
    let filteredData = [...ogDatas];

    if (date) {
      filteredData = filteredData.filter((val) =>
        moment(val.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
      );
    }

    if (searchId) {
      filteredData = filteredData.filter((val) => val._id.includes(searchId));
    }

    setProductDatas(filteredData);
  };

  const handleReset = () => {
    setSearchId("");
    setSelectedDate(null);
    setProductDatas(ogDatas);
  };
  const handleOpen = (transactionId: string) => {
    const transaction = productDatas.find(t => t._id === transactionId) || null;
    setSelectedTransaction(transaction);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const totalOrders = productDatas.length;

  
  return (
    <>
      <section className="py-4">
        <Container>
          <div>
            <h2 className="title">Transactions</h2>
            <div className="py-3">
              <div className="d-flex justify-content-start align-items-center">
                <div>
                  <h3 className="total-H">
                    Total Earnings : <span>${totalEarnings.toFixed(2)}</span>
                  </h3>
                </div>
                <div className="ms-5">
                  <h3 className="total-H">
                    Total Orders : <span>{totalOrders}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-3">
            <Space wrap>
              <DatePicker onChange={onDateChange} />
            </Space>
            <Space wrap>
              <Input
                placeholder="Search by Id"
                className="mx-2"
                value={searchId}
                onChange={onSearchChange}
              />
            </Space>
            <Space wrap>
              <Button variant="text" className="reset-btn" onClick={handleReset}>
                Reset
              </Button>
            </Space>
          </div>
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={productDatas}
              pagination={{ pageSize: 10 }}
            />
            {/* Modal for editing product */}
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="d-flex justify-content-between py-3">
                    <div>
                      <h6 className="view-head">Order details</h6>
                    </div>
                    <div>
                      <MdClear
                        className="view-head-1"
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="view-head-1">Product</div>
                      <div className="view-head-1">Price</div>
                    </div>
                    <hr className="horizontal" />
                    {selectedTransaction&&selectedTransaction.lineItems&&selectedTransaction.lineItems.map((val:any)=>(
                    <><div className="d-flex justify-content-between view-head-2">
                        <div>{val.name?val.name:val.product.slice(-5)}</div>
                        <div>${val&&val.amount?.val.amount.toFixed(2)}</div>
                      </div><hr className="horizontal" /></>
                  ))}
                    <div className="d-flex justify-content-between">
                      <div className="view-head-1">Coupon code</div>
                      <div className="view-head-2">{selectedTransaction&&selectedTransaction.couponCode?selectedTransaction.couponCode:"-"} </div>
                    </div>
                    <hr className="horizontal" />
                    <div className="d-flex justify-content-between">
                      <div className="view-head-1">Coupon Discount</div>
                      <div className="view-head-2">{selectedTransaction&&selectedTransaction.couponDiscount?"-"+selectedTransaction.couponDiscount.toFixed(2):"-"} </div>
                    </div>
                    <hr className="horizontal" />
                    <div className="d-flex justify-content-between">
                      <div className="view-head-1">Total</div>
                      <div className="view-head-2">$ {selectedTransaction?.amount.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Transcation;
